<template>
    <div class="subscribe-edm">
        <div id="mc_embed_signup">
            <form
                id="mc-embedded-subscribe-form"
                action="https://visionfuture.us19.list-manage.com/subscribe/post?u=be391e76a1d636d987ca92cdb&amp;id=8814cc3b93"
                method="post"
                name="mc-embedded-subscribe-form"
                class="validate"
                target="_blank"
                novalidate
            >
                <div id="mc_embed_signup_scroll">
                    <h2 class="edm-title">
                        <div class="text">
                            {{ $t('SubscribeEDM.text') }}
                        </div>
                    </h2>
                    <div class="indicates-required">
                        <span class="asterisk">*</span> {{ $t('SubscribeEDM.asterisk') }}
                    </div>
                    <div class="mc-field-group">
                        <label
                            for="mce-EMAIL"
                        >{{ $t('SubscribeEDM.label') }}<span class="asterisk">*</span>
                        </label>
                        <input
                            id="mce-EMAIL"
                            type="email"
                            value=""
                            name="EMAIL"
                            class="required email"
                        >
                    </div>
                    <div
                        id="mce-responses"
                        class="clear foot"
                    >
                        <div
                            id="mce-error-response"
                            class="response"
                            style="display: none"
                        />
                        <div
                            id="mce-success-response"
                            class="response"
                            style="display: none"
                        />
                    </div>
                    <!-- real people should not fill this in and expect good things
          - do not remove this or risk form bot signups-->
                    <div
                        style="position: absolute; left: -5000px"
                        aria-hidden="true"
                    >
                        <input
                            type="text"
                            name="b_be391e76a1d636d987ca92cdb_8814cc3b93"
                            tabindex="-1"
                            value=""
                        >
                    </div>
                    <div class="optionalParent">
                        <div class="clear foot">
                            <input
                                id="mc-embedded-subscribe"
                                type="submit"
                                :value="$t('SubscribeEDM.input')"
                                name="subscribe"
                                class="button submit-button"
                            >
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {};
    },
    computed: {},
    created() { },
    mounted() { },
    methods: {},
};
</script>

<style lang="scss" scoped>
.subscribe-edm {
  display: flex;
  justify-content: center;
  padding: 50px 30px;

  .edm-title {
    display: flex;
    justify-content: center;

    .text {
      font-weight: 700;
      font-size: 28px;
      line-height: 28px;
      letter-spacing: 0.6em;
      color: $color-gray;
      padding-bottom: 12px;
      border-bottom: 3px solid $color-orange;
    }
  }
}

#mc_embed_signup {
  background: #fff;
  clear: left;
  font: 14px Helvetica, Arial, sans-serif;
  width: 600px;
}
</style>
